<style src="./About.scss" lang="scss" scoped></style>
<script src="./About.js"></script>

<template>
	<section class="about-contain">
		<div class="about-content">
			<el-row :gutter="16" class="about-reverse">
				<el-col :xs="4" :sm="4" :md="5" :lg="5" :xl="4"></el-col>
				<el-col :xs="24" :sm="10" :md="9" :lg="9" :xl="10">
					<div class="about-inner">
						<h1 class="about-page-title">About Us</h1>
						<p class="about-paragraph grap-bt">
							As consumers’ lives transition into the digital realm, advances
							are catalyst with metaverse and parts of it are already existing.
						</p>
						<p class="about-paragraph">
							Since Dec 7th 2021, “METALAB CREATIVE SOLUTIONS” is the join
							forces of “adapter digital co., ltd.” a leading digital innovation
							& creativity firm, “Igloo Studio” a CGI & animation specialist and
							"any.i" a virtual tech specialist. With our fueling-future
							capability & scalability can significantly influence the market &
							demand including longer assets utilization enables room for
							marketing beneficial . Collaborate with creators and developers
							who will really build the ongoing metaverse and fill it with the
							content and experiences. Designing customizable identities,
							contents & experiences for brands to presence and serve consumers
							in their moving forward digital lifestyles.
						</p>
						<img src="@/assets/images/about_brands_content_img.png" />
						<p class="about-paragraph">
							Get ready to immerse your brand in the ongoing Metaverse
							Ecosystem.
						</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
					<img
						src="@/assets/images/about_us.png"
						class="about-image"
						alt="about_us"
					/>
				</el-col>
			</el-row>
		</div>
		<div class="about-page">
			<el-row :gutter="16">
				<el-col :xs="4" :sm="4" :md="5" :lg="5" :xl="4"></el-col>
				<el-col :xs="24" :sm="10" :md="9" :lg="9" :xl="10">
					<h1 class="about-title">About Us</h1>
				</el-col>
			</el-row>
		</div>
	</section>
</template>