<style src="./App.scss" lang="scss"></style>
<script src="./App.js"></script>

<template>
	<div class="app-wrapper">
		<RouterView />
	</div>
	<div class="landscape-notsupport">
		<p class="notsupport-text">Landscape mode is not supported</p>
	</div>
</template>
