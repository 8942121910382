import { toRefs, reactive } from "vue";
import { MetaSlideComponent, VirtualMarketplaceComponent } from "@/components";

export default {
  name: "MetaContents",
  components: {
    MetaSlideComponent,
    VirtualMarketplaceComponent,
  },
  setup(props) {
    const state = reactive({
      metaContentName: "Meta Contents Service",
      metaContentSlides: [
        {
          name: "Virtual Goods",
          image: require("@/assets/images/meta_contents/Virtual_Good.png"),
        },
        {
          name: "Virtual Arts",
          image: require("@/assets/images/meta_contents/Virtual_Art.png"),
        },
        {
          name: "Virtual Fashion",
          image: require("@/assets/images/meta_contents/Virtual_Fashion.png"),
        },
        {
          name: "Virtual Assets & Crypto",
          image: require("@/assets/images/meta_contents/Virtual_Assets_and_Crypto.png"),
        },
      ],
    });
    return { ...toRefs(state) };
  },
};
