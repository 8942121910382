// # Component Pages
import {
  Home,
  About,
  Error,
  Contact,
  MetaContents,
  CurrentEvent,
  MetaWorldViewer,
  AvatarCreationExperience,
} from "@/pages";

// # Layouts
// import MainLayout from "@/views/Layout/MainLayout";
// import BlankLayout from "@/views/Layout/BlankLayout";

export default [
  {
    name: "MainLayout",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "MainLayout" */ "@/layouts/MainLayout"),
    meta: {
      title: "Home",
    },
    redirect: "/",
    children: [
      {
        name: "Home",
        path: "/",
        component: Home,
        props: true,
      },
      {
        name: "About",
        path: "/About",
        component: About,
        props: true,
      },
      {
        name: "Contact",
        path: "/Contact",
        component: Contact,
        props: true,
      },
      {
        name: "MetaWorldViewer",
        path: "/meta-world-viewer",
        component: MetaWorldViewer,
        props: true,
      },
      {
        name: "AvatarCreationExperience",
        path: "/avatar-creation-experience",
        component: AvatarCreationExperience,
        props: true,
      },
      {
        name: "MetaContents",
        path: "/meta-contents",
        component: MetaContents,
        props: true,
      },
      {
        name: "CurrentEvent",
        path: "/current-event",
        component: CurrentEvent,
        props: true,
      },
    ],
  },
  {
    path: "/404",
    component: Error,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
