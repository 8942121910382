import $ from "jquery";
import { onMounted } from "vue";
export default {
  name: "MetalabExperienceComponent",
  inject: ["trackEvent"],
  setup(props) {
    const handleOnEnd = () => {
      console.log("end vdo");
      var media = document.querySelector(".video");
      media.pause();
      media.currentTime = 0;
    };

    onMounted(() => {
      //
    });

    return {
      handleOnEnd,
    };
  },
  mounted() {
    var self = this;
    document.querySelector(".content-link").onclick = ()=>{
      self.trackEvent("EVENT_experience_click_view", {
        event_category: "main_event"
      });
    }
    // document.querySelector("#volume-switcher").onchange = function () {
    //   alert("xx");
    //   document.querySelector("#theplayer").muted = !this.checked;
    // };
  },
};
