<style src="./WelcomeComponent.scss" lang="scss"></style>
<script src="./WelcomeComponent.js"></script>

<template>
	<el-row>
		<el-col>
			<div class="welcome-contain">
				<!-- container video -->
				<div class="container">
					<!-- video -->
					<video
						id="vdo_meta_world"
						class="video"
						muted
						webkit-playsinline
						playsinline
						type="video/mp4"
						data-wf-ignore="true"
						data-object-fit="cover"
						:onended="handleOnEnd"
					>
						<source
							src="@/assets/media/Metalab_webtovr.mp4"
							type="video/mp4"
							data-wf-ignore="true"
						/>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</el-col>
	</el-row>
</template>