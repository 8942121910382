import $ from "jquery";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isOs } from "@/helpers";
import { gsap } from "gsap";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "WelcomeComponent",
  inject: ["trackEvent"],
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const media = ref(null);

    const handleOnEnd = () => {
      console.log("end vdo");

      // media.value.pause();
      // media.value.currentTime = 0;
      // on end to meta world page

      setTimeout(() => {
        router.push({
          name: "MetaWorldViewer",
        });
        gsap.fromTo(
          "#navigation",
          { x: 0, y: -50, autoAlpha: 0, scale: 0.7 },
          {
            x: 0,
            y: 0,
            autoAlpha: 1,
            scale: 1,
            delay: 1,
            duration: 1,
            ease: "Power4.easeOut",
          }
        );
        gsap.fromTo(
          "#sidebar",
          { x: -50, y: 0, autoAlpha: 0, scale: 0.7 },
          {
            x: 0,
            y: 0,
            autoAlpha: 1,
            scale: 1,
            delay: 1,
            duration: 1,
            ease: "Power4.easeOut",
            onComplete: () => {
              media.value.pause();
              media.value.currentTime = 0;
            },
          }
        );
      }, 1000);
    };

    onMounted(() => {
      // gsap.set("#navigation", { x: 0, y: 0 });
      // gsap.set("#sidebar", { x: 0, y: 0 });
      media.value = document.querySelector("#vdo_meta_world");
      // alert(isMobileOnly);
      if (isMobileOnly) {
        // alert("ok");
        media.value.play();
        // media.value.currentTime = 0.5;
      }
      // media.value.pause();
      // media.value.currentTime = 0.5;
    });

    return {
      handleOnEnd,
    };
  },
  mounted() {
    // document.querySelector("#volume-switcher").onchange = function () {
    //   alert("xx");
    //   document.querySelector("#theplayer").muted = !this.checked;
    // };
  },
};
