import { createApp } from "vue";

const app = createApp({});

import { Icon } from ".";

/**
 *  Register components globally
 * */
const components = {
  Icon,
};

Object.keys(components).forEach((key) => app.component(key, components[key]));
