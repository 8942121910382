import $ from "jquery";
import { ref, onMounted } from "vue";
// Import Swiper Vue.js components
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

// swiper bundle styles
// import "swiper/swiper-bundle.min.css";

// swiper core styles
// import "swiper/swiper.min.css";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/scrollbar/scrollbar.scss"; // Pagination module

export default {
  name: "CurrentEventComponent",
  inject: ["trackEvent"],
  props: {
    metaName: String,
    metaSlides: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const mySwiperRef = ref(null);
    const prev = () => {
      mySwiperRef.value.$swiper.slidePrev();
      console.log(mySwiperRef.value);
    };
    const next = () => {
      mySwiperRef.value.$swiper.slideNext();
      console.log(mySwiperRef.value);
    };

    const onSwiper = (swiper) => {
      console.log(swiper);
      mySwiperRef.value = swiper;
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      prev,
      next,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
};
