import { nextTick } from "vue";
import $ from "jquery";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import routes from "./routes";
import { showLoading, hideLoading } from "@/helpers";

// gtag
import { useGtag } from "@/hooks/useGtag";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

// hooks
const { initGtag, trackView, trackEvent } = useGtag();

// init gtag
initGtag();

// Init Loading

router.beforeEach(async (to, from, next) => {
  showLoading();

  window.scrollTo(0, 0);
  // change http to https
  let url = window.location.href;

  if (url.indexOf("https") === -1 && !url.includes("localhost")) {
    url = url.replace("http", "https");
    window.location.href = url;
  }

  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
  // console.log("name: " + to.name);

  if (
    to.name == "Home" ||
    to.name == "About" ||
    to.name == "Contact" ||
    to.name == "CurrentEvent"
  ) {
    $("#navigation").css("visibility", "hidden");
  } else {
    $("#navigation").css("visibility", "visible");
  }

  nextTick(() => {
    trackView({ path: to.name });
    trackEvent(`PAGE_${to.name}`, {
      event_category: "main_event",
    });
  });

  next();
});

router.afterEach((to, from, failure) => {
  // Hide loading
  hideLoading();
});

export default router;
