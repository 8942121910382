<style src="./MetalabExperienceComponent.scss" lang="scss" scoped></style>
<script src="./MetalabExperienceComponent.js"></script>

<template>
	<div class="meta-experience">
		<el-row :gutter="16">
			<el-col :sm="4" :md="3" :lg="5" :xl="3"></el-col>
			<el-col :sm="20" :md="20" :lg="18" :xl="20">
				<div class="meta-experience-contain">
					<!-- container video -->
					<div class="container">
						<!-- video -->
						<video
							class="video"
							muted
							webkit-playsinline
							playsinline
							autoplay
							loop
							type="video/mp4"
							data-wf-ignore="true"
							data-object-fit="cover"
							:onended="handleOnEnd"
						>
							<source
								src="@/assets/media/Meta_Experience_hd_Denoised_beauty.mp4"
								type="video/mp4"
								data-wf-ignore="true"
							/>
							Your browser does not support the video tag.
						</video>
					</div>
				</div>
				<div class="content-box">
					<div class="content-right">
						<h1 class="content-title">
							Virtual
							<span class="new-line">World <span>Viewer</span></span>
						</h1>
						<p class="content-description-mobile">
							The Metaverse will also lead to the establishment of many new
							entertainment franchises, experience and consumer-facing brands.
							This is because new access technologies do more than shift how
							consumers access content virtually.
						</p>
						<a
							href="https://app.spatial.io/rooms/61a9d481d6243d0001c989a3?share=1420889393622625365"
							target="_blank"
							class="content-link"
							>Try this project</a
						>
						<div class="content-xr">
							<div class="xr-support-label">XR Support :</div>
							<ul class="xr-support-list">
								<li class="support-item has-support">VR</li>
								<li class="support-item">AR</li>
								<li class="support-item">MR</li>
								<li class="support-item has-support">WEBXR</li>
							</ul>
						</div>
					</div>
					<div class="content-left">
						<p class="content-description">
							The Metaverse will also lead to the establishment of many new
							entertainment franchises, experience and consumer-facing brands.
							This is because new access technologies do more than shift how
							consumers access content virtually.
						</p>
					</div>
				</div>
			</el-col>
			<el-col :md="1" :lg="1" :xl="1"></el-col>
		</el-row>
	</div>
</template>