<style src="./CurrentEventComponent.scss" lang="scss"></style>
<script src="./CurrentEventComponent.js"></script>

<template>
	<div class="current-event">
		<el-row :gutter="16">
			<el-col :sm="4" :md="5" :lg="5" :xl="4" class="bg-cover"></el-col>
			<el-col :sm="20" :md="19" :lg="19" :xl="20">
				<div class="current-event-contain">
					<!-- container -->
					<div class="container">
						<swiper
							ref="mySwiperRef"
							class="current-event-list"
							slidesPerView="auto"
							:spaceBetween="15"
							:modules="modules"
							:navigation="{
								nextEl: '.meta-arrow-next',
								prevEl: '.meta-arrow-prev',
							}"
							:breakpoints="{
								320: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
								480: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
								640: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
							}"
							:allowTouchMove="false"
							:scrollbar="{ draggable: true }"
							@swiper="onSwiper"
							@slideChange="onSlideChange"
						>
							<swiper-slide
								class="current-event-item"
								v-for="(slide, index) in metaSlides"
								:key="index"
							>
								<div class="current-event-content">
									<div class="current-inner">
										<h1 class="current-event-name" v-html="slide.name"></h1>
										<p class="current-event-detail" v-html="slide.detail" />
									</div>
									<img
										:src="slide.image"
										:alt="slide.name"
										class="current-event-image"
									/>
								</div>
							</swiper-slide>
						</swiper>
					</div>

					<div class="meta-info">
						<div class="meta-content-right">
							<img
								class="meta-arrow-prev"
								src="@/assets/images/arrow_prev.png"
							/>
							<img
								class="meta-arrow-next"
								src="@/assets/images/arrow_next.png"
							/>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>