import { createApp } from "vue";
import App from "./App";
import router from "./router";
// import store from "./store";
import { logger } from "@/helpers";

// Import ElementPlus
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import "element-plus/theme-chalk/display.css";
import locale from "element-plus/lib/locale/lang/th";
import "dayjs/locale/th";

// gtag
import { useGtag } from "@/hooks/useGtag";

// hooks
const { initGtag, trackView, trackEvent } = useGtag();

// Import SCSS and Globals
import "./assets/styles/main.scss";
import "./components/_globals";

const app = createApp(App);
app.provide("logger", logger);
app.provide("initGtag", initGtag);
app.provide("trackView", trackView);
app.provide("trackEvent", trackEvent);
// app.use(store);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
