<style src="./Contact.scss" lang="scss" scoped></style>
<script src="./Contact.js"></script>

<template>
	<section class="contact-contain">
		<div class="contact-content">
			<el-row :gutter="16" class="contact-reverse">
				<el-col :xs="4" :sm="4" :md="5" :lg="5" :xl="4"></el-col>
				<el-col :xs="24" :sm="10" :md="9" :lg="9" :xl="10">
					<div class="contact-inner">
						<div class="contact-top">
							<h1 class="contact-subject">Let's build a metaverse together!</h1>
							<div class="contact-info">
								<div class="mobile-label">Call us:</div>
								<div class="mobile-number">
									<a href="tel:+662-0297830">+662-0297830-7</a>
								</div>
							</div>
							<div class="contact-info">
								<div class="email-label">Send us an email :</div>
								<div class="email-link">
									<a href="mailto:contact@adapterdigital.com">
										contact@adapterdigital.com
									</a>
								</div>
							</div>
							<div class="contact-info">
								<div class="address-label">
									1177 6th-7th Floor, Pearl Bangkok Building, <br />Phaholyothin
									Rd., Phaya Thai, Bangkok 10400
								</div>
								<div class="address-link">
									<a
										href="https://www.google.co.th/maps/place/%E0%B8%AD%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%9E%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%A5+%E0%B9%81%E0%B8%9A%E0%B8%87%E0%B8%84%E0%B9%87%E0%B8%AD%E0%B8%81/@13.7778469,100.5414548,17z/data=!3m1!4b1!4m5!3m4!1s0x30e29dd364f0fbfd:0x38418b7eec6e7624!8m2!3d13.7778469!4d100.5436435"
										target="_blank"
									>
										Google Map
									</a>
								</div>
							</div>
						</div>
						<!-- <div class="contact-bottom">
							<div class="contact-social-info">
								<div class="social-label">Social Network</div>
								<div class="social-link">
									<ul class="social-list">
										<li class="social-item">
											<a
												href="https://www.facebook.com/AdapterDigitalAgency/"
												target="_blank"
												>Facebook</a
											>
										</li>
										<li class="social-item">
											<a
												href="https://www.instagram.com/adapterdigital/"
												target="_blank"
												>Instagram</a
											>
										</li>
									</ul>
								</div>
							</div>
						</div> -->
					</div>
				</el-col>
				<el-col :xs="24" :md="10" :lg="10" :xl="10">
					<img src="@/assets/images/about_us.png" class="contact-image" />
				</el-col>
			</el-row>
		</div>
		<div class="contact-page">
			<el-row :gutter="16">
				<el-col :xs="4" :sm="4" :md="5" :lg="5" :xl="4"></el-col>
				<el-col :xs="24" :sm="10" :md="9" :lg="9" :xl="10">
					<h1 class="contact-title">Contact</h1>
				</el-col>
			</el-row>
		</div>
	</section>
</template>