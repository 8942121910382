<style src="./MetaWorldViewer.scss" lang="scss" scoped></style>
<script src="./MetaWorldViewer.js"></script>

<template>
	<section class="meta-world-viewer-contain">
		<MetalabExperienceComponent />
		<MetaSlideComponent
			v-if="metaContentSlides"
			:meta-name="metaContentName"
			:meta-slides="metaContentSlides"
		/>
	</section>
</template>