<style src="./MetaIdentityComponent.scss" lang="scss" scoped></style>
<script src="./MetaIdentityComponent.js"></script>

<template>
	<el-row :gutter="16">
		<el-col :xs="4" :sm="4" :md="4" :lg="5" :xl="3"></el-col>
		<el-col :xs="24" :sm="12" :md="13" :lg="13" :xl="14">
			<div class="meta-identity-contain">
				<!-- container video -->
				<div class="container">
					<!-- video -->
					<video
						class="video"
						muted
						webkit-playsinline
						playsinline
						autoplay
						loop
						type="video/mp4"
						data-wf-ignore="true"
						data-object-fit="cover"
						:onended="handleOnEnd"
					>
						<source
							src="@/assets/media/Meta_Identty_02_211125_HD_Denoised_beauty.mp4"
							type="video/mp4"
							data-wf-ignore="true"
						/>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</el-col>
		<el-col :xs="24" :sm="8" :md="7" :lg="6" :xl="6">
			<div class="content-box">
				<div class="content-top">
					<h1 class="content-title">
						Virtual
						<span class="new-line">Human <span>& Avatar</span></span>
					</h1>
					<p class="content-description">
						When you step into virtual world, your identity can become anything
						you want, your mimic / digital twins or your hyper-realistic
						avatars. Humans constantly seek out opportunities for signaling and
						self-expression. Increasingly, the ability to interact with others
						is required.
					</p>
				</div>
				<div class="content-bottom">
					<router-link to="#" class="content-link">
						Try this project
					</router-link>
					<div class="content-xr">
						<div class="xr-support-label">XR Support :</div>
						<ul class="xr-support-list">
							<li class="support-item has-support">VR</li>
							<li class="support-item">AR</li>
							<li class="support-item">MR</li>
							<li class="support-item has-support">WEBXR</li>
						</ul>
					</div>
				</div>
			</div>
		</el-col>
	</el-row>
</template>