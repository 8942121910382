<style src="./MetaSlideComponent.scss" lang="scss"></style>
<script src="./MetaSlideComponent.js"></script>

<template>
	<div class="meta-slide">
		<el-row>
			<el-col :sm="4" :md="4" :lg="5" :xl="3" class="bg-cover"></el-col>
			<el-col :sm="20" :md="20" :lg="19" :xl="21">
				<div class="meta-slide-contain">
					<!-- container -->
					<h1 class="title-name">{{ metaName }}</h1>
					<div class="container">
						<swiper
							ref="mySwiperRef"
							class="meta-slide-list"
							slidesPerView="auto"
							:spaceBetween="15"
							:modules="modules"
							:navigation="{
								nextEl: '.meta-arrow-next',
								prevEl: '.meta-arrow-prev',
							}"
							:breakpoints="{
								320: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
								480: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
								640: {
									slidesPerView: 'auto',
									scrollbar: { draggable: false },
								},
							}"
							:allowTouchMove="false"
							:scrollbar="{ draggable: true }"
							@swiper="onSwiper"
							@slideChange="onSlideChange"
						>
							<swiper-slide
								class="meta-slide-item"
								v-for="(slide, index) in metaSlides"
								:key="index"
							>
								<div class="meta-slide-content">
									<h1 class="meta-slide-name">{{ slide.name }}</h1>
									<img
										:src="slide.image"
										:alt="slide.name"
										class="meta-slide-image"
									/>
								</div>
							</swiper-slide>
						</swiper>
					</div>
					<!-- <div class="container">
						<ul class="meta-slide-list">
							<li
								class="meta-slide-item"
								v-for="(slide, index) in metaSlides"
								:key="index"
							>
								<div class="meta-slide-content">
									<h1 class="meta-slide-name">{{ slide.name }}</h1>
									<img
										:src="slide.image"
										:alt="slide.name"
										class="meta-slide-image"
									/>
								</div>
							</li>
						</ul>
					</div> -->
					<div class="meta-info">
						<div class="meta-content-left">
							<h1 class="meta-project-name">{{ metaName }}</h1>
						</div>
						<div class="meta-content-right">
							<img
								class="meta-arrow-prev"
								src="@/assets/images/arrow_prev.png"
							/>
							<img
								class="meta-arrow-next"
								src="@/assets/images/arrow_next.png"
							/>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>