import { toRefs, reactive } from "vue";
import { CurrentEventComponent } from "@/components";

export default {
  name: "CurrentEvent",
  components: {
    CurrentEventComponent,
  },
  setup(props) {
    const state = reactive({
      currentEventSlides: [
        {
          name: `Meta <br/>Thailand 7.0`,
          detail: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
          image: require("@/assets/images/current_event/thailand-7.png"),
        },
        // {
        //   name: `Meta <br/>Thailand 7.0`,
        //   detail: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
        //   image: require("@/assets/images/current_event/thailand-7.png"),
        // },
        // {
        //   name: `Meta <br/>Thailand 7.0`,
        //   detail: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
        //   image: require("@/assets/images/current_event/thailand-7.png"),
        // },
        {
          name: `New Event <span class="new-line">Coming<span> Soon</span></span> `,
          detail: "",
          image: require("@/assets/images/current_event/new_event_coming_soon.png"),
        },
      ],
    });
    return { ...toRefs(state) };
  },
};
