<style src="./VirtualMarketplaceComponent.scss" lang="scss" scoped></style>
<script src="./VirtualMarketplaceComponent.js"></script>

<template>
	<el-row :gutter="16">
		<el-col :sm="4" :md="3" :lg="5" :xl="3"></el-col>
		<el-col :sm="20" :md="20" :lg="18" :xl="20" class="content-reverse">
			<div class="content-box">
				<div class="content-right">
					<h1 class="content-title">Virtual Assets Creation</h1>
					<p class="content-description-mobile">
						A new opportunity to create new value in digital world. The virtual
						assets work everywhere and dominate our endless imaginations and
						fandoms. We will help you find the way to connect into it e.g. asset
						entitlements (earn or buy an outfit in-game, and take it elsewhere)
					</p>
					<router-link to="#" class="content-link"
						>Try this project</router-link
					>
					<div class="content-xr">
						<div class="xr-support-label">XR Support :</div>
						<ul class="xr-support-list">
							<li class="support-item has-support">VR</li>
							<li class="support-item">AR</li>
							<li class="support-item">MR</li>
							<li class="support-item has-support">WEBXR</li>
						</ul>
					</div>
				</div>
				<div class="content-left">
					<p class="content-description">
						A new opportunity to create new value in digital world. The virtual
						assets work everywhere and dominate our endless imaginations and
						fandoms. We will help you find the way to connect into it e.g. asset
						entitlements (earn or buy an outfit in-game, and take it elsewhere)
					</p>
				</div>
			</div>
			<div class="virtual-marketplace-contain">
				<!-- container video -->
				<div class="container">
					<!-- video -->
					<video
						class="video"
						muted
						webkit-playsinline
						playsinline
						autoplay
						loop
						type="video/mp4"
						data-wf-ignore="true"
						data-object-fit="cover"
						:onended="handleOnEnd"
					>
						<source
							src="@/assets/media/Meta_Content_HD_Denoised_beauty.mp4"
							type="video/mp4"
							data-wf-ignore="true"
						/>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
		</el-col>
		<el-col :md="1" :lg="1" :xl="1"></el-col>
	</el-row>
</template>