import { onMounted } from "vue";
import { gsap } from "gsap";
import $ from "jquery";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

export default {
  name: "VirtualMarketplaceComponent",
  inject: ["trackEvent"],
  setup(props) {
    const handleOnEnd = () => {
      console.log("end vdo");
      var media = document.querySelector(".video");
      media.pause();
      media.currentTime = 0;
    };

    const handleScrollToTop = (el) => {
      // console.log(el);
      // alert(el);
      setTimeout(() => {
        const topY = $(el).offset().top - 30;
        // alert(topY);
        gsap.registerPlugin(ScrollToPlugin);
        gsap.to(window, {
          duration: 1,
          ease: "power4",
          scrollTo: topY > 0 ? 0 : 0,
        });
      }, 2000);
    };

    onMounted(() => {
      // window.scrollTo(0, 0);
      // handleScrollToTop("#content-main");
    });

    return {
      handleOnEnd,
    };
  },
};
