// supported: vue@^3.1.1
import { toRefs, reactive } from "vue";
import { logger } from "@/helpers";

export function useGtag() {
  const state = reactive({
    propertyUniversalId: "UA-206587875-6" || null, //UA-XXXXXXXX
    propertyG4Id: "G-C73C02ND9L" || null, //G-XXXXXXXX
    resourceURL: "https://www.googletagmanager.com/gtag/js",
  });

  const initGtag = () => {
    return true;
    // call gtag
    // (function () {
    //   var po = document.createElement("script");
    //   po.async = true;
    //   po.src = `${state.resourceURL}?id=${state.property}`;
    //   var s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(po, s);
    //   var script = document.createElement("script");
    //   script.textContent = `
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(){window.dataLayer.push(arguments);}
    //   gtag('js', new Date());
    //   gtag('config', '${state.propertyUniversalId}');
    //   gtag('config', '${state.propertyG4Id}');`;
    //   document.body.appendChild(script);
    // })();
  };

  const trackView = async ({ path }) => {
    return true;
    // call gtag
    // await window.gtag("config", `${state.property}`, `/${path}`);
    // logger("trackView", { trackView: `/${path}` });
  };

  const trackEvent = async (action, eventTrackData) => {
    return true;
    // const action = '';
    // const eventTrackData = {event_category, event_label, value}
    // const eventData = {action: '', eventTrackData}
    // ex: trackEvent(eventData)

    // call gtag
    // const eventTrack = eventTrackData || {};
    // await window.gtag("event", action, eventTrack);
    // logger({
    //   trackEvent: action,
    // });
    // logger("trackEvent", { action: action });
    // logger("eventTrackData", eventTrack);
  };

  return {
    ...toRefs(state),
    initGtag,
    trackView,
    trackEvent,
  };
}
