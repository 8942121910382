<style src="./CurrentEvent.scss" lang="scss" scoped></style>
<script src="./CurrentEvent.js"></script>

<template>
	<section class="current-event-contain">
		<CurrentEventComponent
			v-if="currentEventSlides"
			:meta-slides="currentEventSlides"
		/>
	</section>
</template>