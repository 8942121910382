import { toRefs, reactive } from "vue";
import { MetaSlideComponent, MetalabExperienceComponent } from "@/components";

export default {
  name: "MetaWorldViewer",
  components: {
    MetaSlideComponent,
    MetalabExperienceComponent,
  },
  setup(props) {
    const state = reactive({
      metaContentName: "Meta Experience Service",
      metaContentSlides: [
        {
          name: "Immersive Experience",
          image: require("@/assets/images/meta_experience/Virtual_Immersive.png"),
        },
        {
          name: "Virtual Community",
          image: require("@/assets/images/meta_experience/Virtual_Community.png"),
        },
        {
          name: "Virtual Store",
          image: require("@/assets/images/meta_experience/Virtual_Store.png"),
        },
        {
          name: "Virtual Chat & Commerce",
          image: require("@/assets/images/meta_experience/Virtual_Chat.jpg"),
        },
      ],
    });
    return { ...toRefs(state) };
  },
};
