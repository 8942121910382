<style src="./AvatarCreationExperience.scss" lang="scss" scoped></style>
<script src="./AvatarCreationExperience.js"></script>

<template>
	<section class="avatar-creation-experience-contain">
		<MetaIdentityComponent />
		<MetaSlideComponent
			v-if="metaContentSlides"
			:meta-name="metaContentName"
			:meta-slides="metaContentSlides"
		/>
	</section>
</template>