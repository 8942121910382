import { toRefs, reactive } from "vue";
import { MetaSlideComponent, MetaIdentityComponent } from "@/components";

export default {
  name: "MetaIdentity",
  components: {
    MetaSlideComponent,
    MetaIdentityComponent,
  },
  setup(props) {
    const state = reactive({
      metaContentName: "Meta Identity Service",
      metaContentSlides: [
        {
          name: "Virtual Avatars & Story",
          image: require("@/assets/images/meta_identity/Virtual_Avatars_and_Story.jpg"),
        },
        {
          name: "Virtual Influencer",
          image: require("@/assets/images/meta_identity/Virtual_Influencers.jpg"),
        },
        {
          name: "Virtual Brand Presenter",
          image: require("@/assets/images/meta_identity/Virtual_Brand_Presenter.jpg"),
        },
      ],
    });
    return { ...toRefs(state) };
  },
};
